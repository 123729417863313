import React, { useState, useRef, useEffect } from 'react';
import './Grid.css';
import { Typewriter } from 'react-simple-typewriter';

const Grid = ( { style }) => {
  const [messages, setMessages] = useState([
    { text: "Hey! I'm Şafak Atakan Çelik - Ata for short. Click the logo or chat with my assistant. I have some keys and forge new ones every day to help and grow together. What do you seek today?", source: "admin" },
  ]);
  
  const chatAreaRef = useRef(null);
  const [userMessageEnteredText, setuserMessageEnteredText] = useState('');
  const userMessageTextAreaRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [isBuffer, setIsBuffer] = useState(false);
  

  const handleUserMessageHandleTextChange = (e) => {
    setuserMessageEnteredText(e.target.value);
  };

  const adjustUserMessageTextAreaHeight = () => {
    const textarea = userMessageTextAreaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
  };

  useEffect(() => {
    adjustUserMessageTextAreaHeight();
  }, [userMessageEnteredText]);

  const handleSendMessage = async () => {
    setuserMessageEnteredText("");
    if (userMessageEnteredText.trim() && !isTyping) {
      setIsBuffer(true);
      setIsTyping(true);
      setMessages([...messages, { text: userMessageEnteredText, source: "user" }]);
  
      let aiResponse;
  
      try {
        
        // if starts with "/note" call a placeholder function and add message "Note Taken" to the messages as aiResponse, else what is below works well anyway.
        if (userMessageEnteredText.startsWith("/note")) {
          // Placeholder function for handling notes
          const note = userMessageEnteredText.replace("/note", "").trim();

          const response = await fetch('/.netlify/functions/take-a-note-to-sheet', {
            method: 'POST',
            body: JSON.stringify({ note }),
          });
  
          if (response.ok) {
            aiResponse = { text: `Your note has been sent to Ata. Thank you for reaching out! Note: "${note}"`, source: 'ai' };
          } else {
            aiResponse = { text: "Oops! Failed to take a note. It seems my ink turned transparent...", source: 'ai' };
          }
          setIsBuffer(false);
          setMessages((prevMessages) => [...prevMessages, aiResponse]);

        } else {
        const response = await fetch('/.netlify/functions/openai-assistant', {
          method: 'POST',
          body: JSON.stringify({ message: userMessageEnteredText }),
        });
  
        const data = await response.json();
        aiResponse = { text: `${data.reply}`, source: 'ai' };
        setIsBuffer(false);
        setMessages((prevMessages) => [...prevMessages, aiResponse]);
        }
      } catch (error) {
        aiResponse = { text: "Oops -- something went wrong. I'm on it!", source: 'ai' };
        setIsBuffer(false);
        setMessages((prevMessages) => [...prevMessages, aiResponse]);
      }
  
      // Reset typing state after the animation is likely to be complete
      const typeSpeed = 30;
      const messageLength = aiResponse.text.length;
      const typingTime = typeSpeed * messageLength;
  
      setTimeout(() => {
        setIsTyping(false);
      }, typingTime);      
  
    
    }
  };
  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent new line creation
      handleSendMessage();
    }
  };


  const scrollToBottom = () => {
    setTimeout(() => {
      const chatArea = chatAreaRef.current;
      chatArea.scrollTop = chatArea.scrollHeight;
    }, 150);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);  

  return (
    <div className="grid-container" style={style}>
      <div className="chat-area" ref={chatAreaRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.source}-message`}>
            {message.source !== "user" ? (
              <Typewriter
                words={[message.text]} // Ensure this is an array with a single string
                typeSpeed={30}
                deleteSpeed={50}
                delaySpeed={1000}
                cursor={false}
                // onLoopDone={() => setIsTyping(false)}
                // onType={() => setIsTyping(true)}
              />
            ) : (
              message.text
            )}
          </div>
        ))}

          {isBuffer && (
          <div className="message ai-message">
            H<Typewriter
              words={["mm..."]}
              loop={true}
              typeSpeed={30}
              deleteSpeed={50}
              delaySpeed={1000}
              cursor={false}
            />
          </div>
        )}


      </div>

      <div className="input-area">
        <textarea 
          ref={userMessageTextAreaRef}
          value={userMessageEnteredText}
          onChange={handleUserMessageHandleTextChange}
          onKeyDown={handleKeyDown}
          placeholder={isTyping ? "Wait for your turn..." : "Type here..."}
          rows="1"
          style={{ 
            flex: 1, 
            border: 'none', 
            outline: 'none', 
            background: 'transparent', 
            resize: 'none', 
            width: '100%',
            overflowY: 'auto',
          }}
        />
        <div 
          className={`input-area-send-button ${isTyping ? 'disabled' : ''}`} 
          onClick={handleSendMessage} 
          role="button"
        >
          ↑
        </div>
      </div>
    </div>
  );
};

export default Grid;
