// src/App.js
import React, { useState } from 'react';
import Grid from './components/Grid';
import BackGrid from './components/BackGrid';
import LogoSafakan from './components/LogoSafakan';
import './App.css'; // Import your CSS file for styling

function App() {
  // State to toggle between Grid and BackGrid
  const [showGrid, setShowGrid] = useState(true);
  const [transition, setTransition] = useState('fade-in');

  // Toggle handler
  const handleLogoClick = () => {
    setTransition('fade-out');
    setTimeout(() => {
      setShowGrid(prevState => !prevState);
      setTransition('fade-in');
    }, 300); // This should match the duration of your fade-out animation
  };

  const gridStyle = {
    transition: 'opacity 0.3s ease-in-out',
    opacity: showGrid && transition === 'fade-in' ? 1 : 0,
    position: showGrid ? 'relative' : 'absolute',
    textAlign: 'left',
  };

  const backGridStyle = {
    transition: 'opacity 0.3s ease-in-out',
    opacity: !showGrid && transition === 'fade-in' ? 1 : 0,
    position: !showGrid ? 'relative' : 'absolute',
    textAlign: 'center',
  };

  return (
    <div className="App">
      <LogoSafakan onClick={handleLogoClick} />
      <div style={gridStyle}>
        <Grid />
      </div>
      <div style={backGridStyle}>
        <BackGrid />
      </div>
    </div>
  );
}

export default App;
