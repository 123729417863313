import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaGithub, FaVideo, FaEnvelope } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 2px;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  background-color: transparent;
  color: #00FFFF;
  border: 1px solid #3b3b3b;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  @media (max-width: 480px) {
    padding: 10px 15px;
    font-size: 16px;
  }
  
  @media (min-width: 518px) {
    padding: 20px 35px;
    font-size: 23px;
  }
`;

const Icon = styled.span`
  @media (max-width: 480px) {
    font-size: 18px;
  }
  
  @media (min-width: 518px) {
    font-size: 24px;
  }
`;


const ConnectBar = () => {
  const handleLinkedIn = () => {
    window.open('https://www.linkedin.com/in/safakatakancelik', '_blank');
  };

  const handleGitHub = () => {
    window.open('https://github.com/ataguru', '_blank');
  };

  const handleMeetingRequest = () => {
    window.open('https://calendly.com/safakatakancelik/', '_blank');
  };

  const handleMailRequest = () => {
    window.open('mailto:safakatakancelik@gmail.com', '_blank');
  };

  return (
    <Container>
      <ButtonContainer>
        <Button onClick={handleGitHub}>
          <Icon as={FaGithub} />
        </Button>
      </ButtonContainer>

      <ButtonContainer>
        <Button onClick={handleMailRequest}>
          <Icon as={FaEnvelope} />
        </Button>
      </ButtonContainer>

      <ButtonContainer>
        <Button onClick={handleMeetingRequest}>
          <Icon as={FaVideo} />
        </Button>
      </ButtonContainer>

      <ButtonContainer>
        <Button onClick={handleLinkedIn}>
          <Icon as={FaLinkedin} />
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ConnectBar;