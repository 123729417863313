import './LogoSafakan.css';

const LogoSafakan = ( { onClick } ) => {
    return (
    <div className="logo-container">
    <img src={`${process.env.PUBLIC_URL}/drawingCandidate1.png`} alt="Logo" className="logo-image" onClick={onClick} style={{ cursor: 'pointer' }}  />
    </div>    
    )
}

export default LogoSafakan;