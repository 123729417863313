import './BackGrid.css';
import ConnectBar from './ConnectBar';


const BackGrid = ( {style}) => {


    return (
        <div className="grid-container-back" style={style}>
            <div className="about-me-area">
                <p>
                About Me
                <hr></hr>
                I'm a human who loves to learn and share. <br/>
                I only know what I know and I constantly grow. <br/> 
                . <br/>
                My story lead me to explore: <br/>
                software, design, data, engineering, machine learning, music, writing, storytelling, diversity, ...<br/>
                . <br/>
                Şafak Atakan Çelik
                </p>

            <ConnectBar/>
            
            </div>
        </div>
    );
};

export default BackGrid;